<template>
  <div class="sms_template_list_add">
    <el-card>
      <el-page-header @back="goBack" style="margin-bottom: 20px;"></el-page-header>
    </el-card>
    <el-card style="margin-top: 10px">
      <el-form ref="exhibitionForm" :model="exhibitionForm" label-width="100px" :inline="false" size="normal">
        <el-form-item label="关联活动">
          <el-col :span="8">
            <fuzzy-selection ref="fuzzySelection" :value.sync="exhibitionForm.activityId" :type="4" clearable
              tipName="活动" />
          </el-col>
        </el-form-item>
        <el-form-item label="展会名称">
          <el-col :span="8">
            <el-input v-model="exhibitionForm.name" show-word-limit />
          </el-col>
        </el-form-item>
        <el-form-item label="海报模版">
          <el-col :span="8">
            <uplist
            :action="action"
            :list="this.exhibitionForm.poster"
            @changes="c_img"
          />
          </el-col>
        </el-form-item>
      </el-form>
      <el-form ref="couponForm" :model="couponForm" :rules="rule" label-width="100px" :inline="false" size="normal">
        <el-form-item label="优惠券名称" prop="name">
          <el-col :span="8">
            <el-input v-model="couponForm.name" show-word-limit />
          </el-col>
        </el-form-item>
        <el-form-item label="有效期" prop="bonus">
          <el-date-picker v-model="couponForm.time" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="优惠券数量" prop="number">
          <el-col :span="8">
            <el-input-number v-model="couponForm.number" :step="1"></el-input-number>
          </el-col>
        </el-form-item>
        <el-form-item label="优惠方式" prop="discountType">
          <el-radio-group v-model="couponForm.discountType" :disabled="couponForm.type == 2">
            <el-radio :label="0">折扣减免</el-radio>
            <el-radio :label="1">定额减免</el-radio>
          </el-radio-group>
          <div style="height:10px"></div>
          原价减
          <el-select style="width:100px" v-if="couponForm.type == 2" size="small" v-model="couponForm.discountValue">
            <el-option :value="20" label="20"> </el-option>
            <el-option :value="10" label="10"> </el-option>
          </el-select>
          <el-input v-else size="small" v-model="couponForm.discountValue" style="width:100px"></el-input>
          {{ couponForm.discountType == 0 ? "%" : "元" }}
        </el-form-item>
        <el-form-item label="使用限制">
          <el-input size="small" style="width:100px" v-model="couponForm.limit"></el-input>
          每人可以领取多少张
          <br />
          <div class="warning" style="color:#fda400;line-height:30px">
            若不限次数，请填0
          </div>
        </el-form-item>
        <el-form-item label="类型">
          <el-radio-group v-model="couponForm.type" @change="typeChange">
            <!-- <el-radio :label="0">通用</el-radio> -->
            <el-radio :label="1">活动</el-radio>
            <el-radio :label="2">VIP会员</el-radio>
          </el-radio-group>
        </el-form-item>
        <template v-if="couponForm.type == 1">
          <el-form-item label="选择活动">
            <el-col :span="8">
              <fuzzy-selection ref="fuzzySelection" :value.sync="couponForm.activityId" :type="4" clearable
                tipName="活动" />
            </el-col>
          </el-form-item>
          <el-form-item label="适用票种" prop="ticketIds">
            <el-checkbox-group v-model="couponForm.ticketIds">
              <!-- <el-checkbox label="-1">全部</el-checkbox> -->
              <el-checkbox :label="item.id + ''" v-for="(item, index) in tickList" :key="index">{{
                item.name
              }}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="票种类型" prop="ticketType">
            <el-radio-group v-model="couponForm.ticketType">
              <el-radio :label="0">单人票</el-radio>
              <el-radio :label="1">团体票</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="小程序" prop="miniprogramType">
            <el-radio-group v-model="couponForm.miniprogramType">
              <!-- <el-checkbox label="-1">全部</el-checkbox> -->
              <el-radio :label="item.id" v-for="(item, index) in [
                { id: 0, name: '扬帆小程序' },
                { id: 1, name: 'PAGC小程序' },
              ]" :key="index">{{ item.name }}</el-radio>
            </el-radio-group>
          </el-form-item>
        </template>
        <el-form-item label="描述">
          <el-col :span="8">
            <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="couponForm.desc"> </el-input>
          </el-col>
        </el-form-item>
      </el-form>
      <el-button type="primary" size="small" @click="onSubmit">{{ "提交" }}</el-button>
      <!-- <el-button size="small" @click="preview">{{ "预览" }}</el-button> -->
    </el-card>
    <el-dialog title="展商列表" :visible.sync="addEnterpriseDialogVisible" width="60%" @close="toggleSelection()" center>
      <nav class="input" style="width: auto">
        <el-input v-model="search.keyword" size="medium" placeholder="请输入企业名称" />
      </nav>
      <nav class="input" style="width: 150px">
        <el-button size="small" @click="resetList">重置</el-button>
        <el-button type="primary" size="small" @click="$refs.exhibitorTable.reload()">{{ "搜索" }}</el-button>
      </nav>
      <z-table ref="exhibitorTable" v-model="exhibitorList" @query="queryList" fullscreenLoading>

        <el-table ref="multipleTable" @selection-change="handleSelectionChange" :data="exhibitorList"
          style="width: 100%" border stripe :header-cell-style="rowClass">
          <el-table-column type="selection" width="55">
          </el-table-column>
          <el-table-column prop="id" label="ID"> </el-table-column>
          <el-table-column prop="enterpriseName" label="绑定企业"> </el-table-column>
          <el-table-column prop="name" label="账号"> </el-table-column>
          <el-table-column prop="remark" label="备注"> </el-table-column>
          <el-table-column prop="createTime" label="创建时间"> </el-table-column>
          <!--            <el-table-column label="操作">-->
          <!--              <template slot-scope="{ row }">-->
          <!--                <el-button-->
          <!--                    @click="onDelete(row)"-->
          <!--                    type="text"-->
          <!--                    icon="el-icon-delete"-->
          <!--                    size="mini"-->
          <!--                    style="color: red;"-->
          <!--                >删除</el-button>-->
          <!--              </template>-->
          <!--            </el-table-column>-->
        </el-table>
      </z-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="toggleSelection()">取 消</el-button>
        <el-button type="primary" @click="addEnterprise">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import list from "/src/mixin/list.js";
import rules from "@/utils/rules";
import { debounce } from "@/utils/methods";
import Img from "@/views/enterprise/enterprise_cert_list/img.vue";
import exhibitor from "@/views/enterprise/exhibitor.vue";
import params from "@/views/goods/params.vue";
import fuzzySelection from "@/components/fuzzySelection";
import uplist from '@/components/uplist/index'
export default {
  computed: {
    exhibitor() {
      return exhibitor
    }
  },
  components: { Img, fuzzySelection ,uplist},
  mixins: [list],
  data() {
    return {
      tickList: [],
      exhibitionForm: {
        activityId: "",
        poster: [],
        name: ""
      },
      id: '',
      couponForm: {
        name: "",
        discountType: "",
        ticketIds: [],
        time: [],
        beginTime: "",
        endTime: "",
        number: "",
        limit: "0",
        discountValue: "",
        desc: "",
        activityId: "",
        bonus: "",
        type: 1,
        miniprogramType: 0,
        ticketType: 0,
      },
      rule: rules.reqAll({
        name: "请输入优惠券名称",
        ticketIds: "请选择票种",
        logo: "请输入企业logo",
        city: "请输入省份和城市",
        people: "请输入公司人事",
        financing: "请输入融资情况",
        type: "请输入类型",
        business: "请输入行业",
        serverArea: "请输入业务区域",
        companyDesc: "请输入企业介绍",
        intro: "请输入企业简介",
      }),
      action: {
        action: "bonus",
      },
      pageData: {
        name: '展商活动'
      },
      search: {
      },
      addForm: {
        categoryId: "1",
        enterpriseId: ""
      },
      exhibitorForm: {
        ids: "",
        exhibitionId: ""
      },
      exhibitorSearch: {
        keyword: "",
        exhibitionId: ""
      },
      addEnterpriseDialogVisible: false,
      state: "",
      searchList: [],
      searchInput: false,
      exhibitorName: "",
      baseurl: this.$store.state.env.cos,
      yu: "/" + this.$store.state.env.domain,
      enterpriseList: [],
      pageStatus: 1,
      // 展商列表
      exhibitorList: [],
      //   对话框表格多选列表
      multipleSelection: [],
      first: true,

    };
  },
  activated() {
    console.log(1);
  },
  created() {
    this.getform()
  },
  watch: {
    "couponForm.activityId"(newValue, oldValue) {
      this.findTick();
      if (this.first) {
        return;
      }
      this.couponForm.ticketIds = [];
    },
    // "form.type"(val) {
    //     if (val == 2) {
    //         this.form.discountValue = 20;
    //         this.form.discountType = 0;
    //     }
    // },
  },
  methods: {
    c_img(val) {
      this.exhibitionForm.poster = val
    },
    async getform() {
      if (!this.$route.query.id) {
        return;
      }
      this.id = this.$route.query.id;
      const { data: res } = await this.$http.get("/admin/Exhibition/getExhibitionInfo?exhibition_id=" + this.id);
      if (res.errorCode == 200) {
         const configuration =JSON.parse(res.data.configuration)
        console.log(configuration)
        this.couponForm = {
          ...configuration.coupon
        };
        console.log(res)
        this.exhibitionForm={
         ...res.data,
          poster:configuration.poster
        }
        console.log(this.exhibitionForm)

        this.$nextTick(() => {
          if (!this.$refs.fuzzySelection) {
            return;
          }
          this.$refs.fuzzySelection.options = [{ id: this.couponForm.activityId, title: this.couponForm.activityName }];
          this.$refs.fuzzySelection.val = this.couponForm.activityId;
        });
      }
      //  this.form.initViews =
    },
    async findTick() {
      var { data: res } = await this.$http.get(
        `/admin/Activity/getTicketList?activityId=${this.couponForm.activityId}&page=1&pageSize=5000`
      );

      //   console.log(res)
      this.tickList = res.data.list;
      this.first = false;
    },
    typeChange() {
      if (this.couponForm.type == 2) {
        this.couponForm.discountValue = 20;
        this.couponForm.discountType = 0;
      }
    },
    async onSubmit() {
      var url;
      if (this.id) {
        url = "/admin/Exhibition/editExhibition";
      } else {
        url = "/admin/Exhibition/addExhibition";
      }
const configuration={
    "coupon":this.couponForm,
        "poster": this.exhibitionForm.poster,
  }
      var { data: res } = await this.$http.post(url, {
        exhibition_id: this.id,
        name: this.exhibitionForm.name,
        activityId: this.exhibitionForm.activityId,
        configuration: JSON.stringify(configuration),
      });
      if (res.errorCode == 200) {
        this.$message.success(res.message);
        this.$router.push("/exhibition_list");
      }
    },
    /**
     * 对话框表格多选
     * @param val
     */
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    onEdit(row) {
      this.$router.push({ name: "exhibitior_list", query: { id: row.id } });
    },
    async getExhibitorList(val) {
      this.exhibitorSearch.exhibitionId = this.$route.query.id
      console.log(this.exhibitorSearch)
      let { data: res } = await this.$http.get("/admin/Exhibition/getExhibitorList", { params: { ...val, ...this.exhibitorSearch } });

      if (res.errorCode == 200) {
        this.pageData = res.data.exhibition
        this.$refs.table.complete(res.data);
      }
    },

    toClose() {
      setTimeout(() => {
        this.searchInput = false;
      }, 300);
    },
    /**
     * 添加展会
     * @returns {Promise<void>}
     */
    async addEnterprise() {
      this.exhibitorForm.ids = this.multipleSelection.map(item => item.id).join(',')
      this.exhibitorForm.exhibitionId = this.$route.query.id
      let { data: res } = await this.$http.post("/admin/Exhibition/addExhibitor", { ...this.exhibitorForm });
      this.exhibitorForm = {
        ids: "",
        exhibitionId: ""
      },
        this.toggleSelection()
      this.multipleSelection = []

      this.getlist()

    },
    // 获取展会列表
    getlist() {
      this.$refs.table.refresh();
      console.log(this.search)
      console.log(this.voteCategoryList)
    },
    // // 获取展会展商名单
    // getExhibitorList(){
    //   this.$refs.exhibitorTable.refresh();
    // },
    /**
     * 获取展商列表
     * @param val
     * @returns {Promise<void>}
     */
    async queryList(val) {
      let { data: res } = await this.$http.get("/admin/Exhibition/getAccountList", {
        params: { ...val, ...this.search },
      });
      if (res.errorCode == 200) {
        this.$refs.exhibitorTable.complete(res.data);
      }

    },
    /**
     * 删除展会
     * @param row
     * @returns {Promise<void>}
     */
    async onDelete(row) {
      await this.$confirm("此操作将永久删除该, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      });
      let { data: res } = await this.$http.post("/admin/Exhibition/delExhibitor", row);
      if (res.errorCode == 200) {
        this.$message.success(res.message);
        this.getlist();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.for {
  transform: scale(0.5);
  transform-origin: 0 0;
}

.tips {
  color: gray;

  span {
    color: red;
  }
}
</style>
